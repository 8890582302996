import React from "react";
import "./Team.css";
import Team1 from "../assets/images/team1.png";
import Team2 from "../assets/images/team2.png";
import Twitter from "../assets/images/twitter.svg";
import Flower from "../assets/images/flower.png";




export default function Team() {
  return (
    <section className="team py-5" id="team">
      <div className="container">
        <div className="row py-5">
          <div className="col-md-8 col-xs-12 mx-auto">
            <h1 className="team_title title-font">TEAM</h1>
            <div className="team-section">
              <div className="row">
                <div className="col-sm-6" data-aos="fade-up">
                  <div className="card mt-3" style={{ Width: "18rem" }}>
                    <img src={Team1} className="card-img-top" alt="..." />
                    <div className="card-body text-center">
                      <h3 className="card-title">Dr. Bane</h3>
                      <h5 className="card-title">Scientist & Senior Business Consultant, Founder & Project Manager</h5>
                      <h6 className="card-title">Hi I am Dr. Bane. I am a scientist by education and a senior business consultant as profession. With over 8 years of experience in leading business divisions in German as well as MNCs, like, Sartorius, Marabu, etc., I have decided to use my expertise to develop Hootles NFT project to grow awareness and preserve endangered Owl species. Let´s be a Hootler to save the precious nocturnal gems. </h6>
                    </div>
                    <a href="https://twitter.com/blockybane" className="p-3">
                      <img src={Twitter} alt="twiter" className="twitter" />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6" data-aos="fade-up">
                  <div className="card mt-3" style={{ Width: "18rem" }}>
                    <img src={Team2} className="card-img-top" alt="..." />
                    <div className="card-body text-center">
                      <h3 className="card-title">Spirona</h3>
                      <h5 className="card-title">Digital artist and PR</h5>
                      <h6 className="card-title">Heyya, I am Spirona. I am a designer by profession with over 5 years of experience in working in start-ups to MNCs as design lead. I am very happy to engage myself to the Hootles project and look forward to use my skills in saving a beautiful species. I love to interact, so ask me anything folks. I am always here for you.</h6>
                    </div>
                    <a href="https://twitter.com/Spironaa" className="p-4">
                      <img src={Twitter} alt="twiter" className="twitter" />
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <img src={Flower} alt="flower" className="flower" />
      </div>
      <br />
    </section>
  );
}
