import React, { useState, useEffect } from "react";
import Home from "../Page";
import "./View.css";




export default function Page() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, []);
  return (
    
    <div>

      {loading ? (
        <div className="page-loader">
          <div className="spinner"></div>
          <div className="txt">
            <span style={{ color: "#ace8fb" }}>HOOTLES</span>

          </div>

        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}