/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.css";
import Twitter from "../assets/images/twitter.svg";
import Discord from "../assets/images/discord.svg";
import Instagram from "../assets/images/instagram.svg";
import LogoNavbar from "../assets/images/logo2.png";
import Nft from "../assets/images/nft.png";
import Nft1 from "../assets/images/nft1.jpg";
export default function Footer() {
  return (
    <section
      className="h-100 w-100 bg-white"
      style={{ boxSizing: "border-box" }}
    >
      <footer
        className="footer-4-2 h-100"
        style={{ fontFamily: "'Inter', sans-serif" }}
      >
        <div className="main">
          <div className="">
            <div className="container d-flex justify-content-between align-items-center m-auto flex-lg-row flex-column-reverse gap-4 position-relative">
              <nav className="text-base d-flex align-items-center gap-3 text-gray-1">
                <img src={LogoNavbar} alt="logo" />
                <p className="text-gray-1 m-0">
                Hootles © 2022 All rights reserved
                </p>
              </nav>
              <div>
              <a href="https://nftstudio24.com/product_brand/hootles/" className="p-2">
                      <img src={Nft1} alt="nftcalendar1" className="nftcalendar1" />
                    </a>
                </div>
                <div>
              <h3 className="pa">
               As seen on
              </h3>
              <a href="https://nftcalendar.io/event/hootles-pre-sale-virtual-meet-up/" className="p-1">
                      <img src={Nft} alt="nftcalendar" className="nftcalendar" />
                    </a>
                    
                    </div>      
              <div className="d-flex gap-lg-4">
                <div className="d-flex gap-3">
                              
                <a href="https://twitter.com/Hootles_NFT" className="p-3">
                      <img src={Twitter} alt="twiter" className="twitter" />
                    </a>
                    <a href="https://discord.gg/ew7e9UPFVW" className="p-3">
                      <img src={Discord} alt="discord" className="discord" />
                    </a>
                    <a href="https://www.instagram.com/hootles_nft/" className="p-3">
                      <img src={Instagram} alt="twiter" className="instagram" />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}