import React from "react";
import Header from "../Header";
import About from "../About";
import Roadmap from "../Roadmap";
import Faq from "../Faq";
import Team from "../Team";
import Footer from "../Footer";
import Storyboard from "../Storyboard";
import "./Page.css";

export default function Home() {
  return (
    <>
      <Header />
      <About />
      <Roadmap/>
      <Storyboard/>
      <Faq />
      <Team />
      <Footer />
    </>
  );
}