import React from "react";
import "./Faq.css";
import Faq2 from "../assets/images/Faq2.png";
import Faq3 from "../assets/images/Faq3.png";
import Flower3 from "../assets/images/flower3.png";
import {motion} from "framer-motion";



export default function Faq() {
  return (




    <section className="faq py-5" id="faq">
     
      
     
      <div className="container">
        
        <div className="row py-5">
          <div className="col-md-8 col-xs-12 mx-auto">




         <motion.div  animate={{ x:2, y:-80 }}
          transition={{ 
          delay: 1,
          repeat: Infinity,
          repeatType: 'reverse',
          repeatDelay: 1,
          duration: 4,
  
          }}
         
         >
        <img src={Faq2} alt="" className="Faq2" />
        </motion.div>

            <h1 className="faq_title title-font">FAQ</h1>

            <div className="accordion" id="accordionExample">

            <div
                className="accordion-item accordion_one active_border"
                data-aos="fade-up"
              >
                <h2 className="accordion-header" id="headingOne1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne1"
                    aria-expanded="false"
                    aria-controls="collapseOne1"
                  >
                    1. Brief Roadmap
                  </button>
                </h2>
                <div
                  id="collapseOne1"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne1"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  <li>Pre-launch virtual event</li>
                  <li>Guaranteed 30% discounted price on pre-sale whitelist minting</li>
                  <li>Unlimited earning potential as Hootles affiliate. Hootles is the only NFT collection integrated to affiliate concept</li>
                  <li>Launching `Thinkers Club´ and `Action Club´</li>
                  <li>Giving away Hootles art prints signed by Dr. Bane</li>
                  <li>Nocturnal Hootie Party</li>
                  <li>Launching Hootles merchendise by handcrafted wooden toys made by Indian tribals</li>
                  <li>Play-to-earn (P2E) Hootles staking game</li>
                  <li>Plans for Hootles Genesis collection</li>
                  </div>
                </div>
              </div>


              <div className="accordion-item accordion_one" data-aos="fade-up">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. What is the supply?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  <li>3333 Hootles</li>
                    </div>
                </div>
              </div>







              <div className="accordion-item accordion_one" data-aos="fade-up">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Will you be on secondary markets?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  <li>Yes we will be on secondary markets as soon as we sell out!</li>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordion_one" data-aos="fade-up">
                <h2 className="accordion-header" id="headeingfour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    4. What is the price ?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headeingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    
                  <li> 
                  Pre-Sale: 110 MATIC
                  </li> 
                  <li> 

                  Public Sale: 150 MATIC  
                  </li>     
                    </div>
                </div>
              </div>
            </div>
            <motion.div       animate={{ y: 100, x:10  }}
        transition={{ 
        delay: 1,
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
        duration: 5,
        }}
        > 
        <img src={Faq3}  alt="" className="Faq5"  />
        </motion.div>

          </div>

        </div>
        <img src={Flower3} alt="flower" className="flower" />
        


      </div>
      <br />

    </section>
    

  );
}
