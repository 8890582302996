//import logo from './logo.png';
import React from 'react'
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className="h1">HOOTLES</h1>
      <a class="btn btn-primary btn-lg" href="https://mint.hoootles.com" role="button">MINT NOW</a>
    </div>
  );
}

export default App;
