/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import App from "../../App";
import "./Header.css";
import Logo from "../assets/images/logo.png";
import Gif from "../assets/images/gif.png";
import Twitter from "../assets/images/twitter.svg";
import Discord from "../assets/images/discord.svg";
import Instagram from "../assets/images/instagram.svg";





export default function Header() {
  return (
    <>
      <section className="h-100 2-100 header">
        <div className="header-3-1" style={{ fontFamily: "'Poppins', sans-serif" }}>
          <nav className="navbar navbar-expand-lg navbar-light">
            <a ><img style={{ marginRight: "0.1rem" }} src={Logo} alt="logo" /></a>
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#targetModal-item"
            >
            <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="modal-item modal fade"
              id="targetModal-item"
              tabIndex="0"
              role="dialog"
              aria-labelledby="targetModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div
                  className="modal-content border-0"
                  style={{
                    background: "#dae7f8",
                  }}
                >
                <div
                  className="modal-header border-0"
                  style={{ padding: "2rem", paddingBottom: 0 }}
                >
                <a className="modal-title" id="targetModalLabel">
                <img
                style={{ marginTop: " 0.1rem" }}
                src={Logo}
                alt="logo"
                />
                </a>
                <button
                type="button"
                className="close btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
                </div>
                <div
                className="modal-body"
                style={{
                padding: "2rem",
                paddingTop: 0,
                paddingBottom: 0,
                }}
                >
                <ul className="navbar-nav responsive me-auto mt-2 mt-lg-0">
                <li className="nav-item position-relative">
                <a className="nav-link" href="#about" onclick="return false;" > 
                    ABOUT
                </a>
                </li>

                <li className="nav-item  position-relative">
                  <a className="nav-link " href="#roadmap">
                    HOOTMAP
                  </a>
                </li>

                <li className="nav-item  position-relative">
                  <a className="nav-link " href="#storyboard">
                    BENEFITS
                  </a>
                </li>

                <li className="nav-item position-relative">
                  <a className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item position-relative">
                  <a className="nav-link" href="#team">
                    TEAM
                  </a>
                </li>
                    </ul>
                  </div>
                  <div
                    className="modal-footer border-0"
                    style={{ padding: "2rem", paddingTop: "0.75rem" }}
                  >
                    <a href="https://twitter.com/Hootles_NFT" className="p-3">
                      <img src={Twitter} alt="twiter" className="twitter" />
                    </a>
                    <a href="https://discord.gg/ew7e9UPFVW">
                      <img src={Discord} alt="discord" className="discord" />
                    </a>
                    <a href="https://www.instagram.com/hootles_nft/" className="p-3">
                      <img src={Instagram} alt="twiter" className="instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo">
              <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
                <li className="nav-item position-relative">
                  <a className="nav-link" href="#about">
                    ABOUT
                  </a>
                </li>

                <li className="nav-item  position-relative">
                  <a className="nav-link " href="#roadmap">
                    HOOTMAP
                  </a>
                </li>

                <li className="nav-item  position-relative">
                  <a className="nav-link " href="#storyboard">
                    BENEFITS
                  </a>
                </li>


                <li className="nav-item position-relative">
                  <a className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item position-relative">
                  <a className="nav-link" href="#team">
                    TEAM
                  </a>
                </li>            
              </ul>
              <a href="https://twitter.com/Hootles_NFT" className="p-3">
                      <img src={Twitter} alt="twiter" className="twitter" />
                    </a>
                    <a href="https://discord.gg/ew7e9UPFVW">
                      <img src={Discord} alt="discord" className="discord" />
                    </a>
                    <a href="https://www.instagram.com/hootles_nft/" className="p-3">
                      <img src={Instagram} alt="twiter" className="instagram" />
                    </a>
             </div>
             </nav>
             <div data-aos="zoom-in">
             <div className="mx-auto hero">
             <h1 className="text-center">
              </h1>
              <img
                src={Gif}
                alt="ss"
                className="img"
              />
              <App />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
