import About1 from "../assets/images/about1.png";
import About2 from "../assets/images/about2.png";
import About3 from "../assets/images/about3.png";
import About4 from "../assets/images/about4.png";
import About5 from "../assets/images/about5.png";
import About6 from "../assets/images/about6.png";
import Flower4 from "../assets/images/flower4.png";
import about2 from "../assets/images/2about.png";
import about3 from "../assets/images/3about.png";
import ng from  "../assets/images/ng.png";
import nh from  "../assets/images/nh.png";
import nft from '../assets/images/nft.mp4'

import {motion} from "framer-motion";
import React from 'react'



import "./About.css";
export default function About() {
  return (
   <section className="about" id="about">
    <img src={Flower4} alt="flower" className="flower" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-xs-12 mx-auto">

            <motion.div animate={{ y: 20  }}
             transition={{ 
             delay: 1,
             repeat: Infinity,
             repeatType: 'reverse',
             repeatDelay: 1,
             duration: 5,
             }}
             >
             <img src={about2}  alt="" className="about2" />
            </motion.div>       
       
            <motion.div animate={{ y: -30  }}
            transition={{ 
            delay: 1,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 1,
            duration: 5,
            }} 
            >
            <img src={about3} alt="" className="about3" />
            </motion.div>

            <h1 className="my-5">ABOUT</h1>

            <p className="card-text">
            Hootles is a P2E game centered NFT collection on the Polygon blockchain consisting of 3,333 cute & friendly owls. Each Hootle is one-of-a-kind and 100% owned by you; it cannot be replicated, taken away, or destroyed. Hootles, for the first time in NFT domain, plans to incorporate the potential of affiliate marketing to offer unlimited earning potential to its holders. The vision of Hootles is to create a universal brand that can be enjoyed both digitally as well as physically by the age group eight to eighty. Expect opportunity, growth, fun, surprises and engagement in every step. The project is made by the people for the people.  
            <p>The project is made by the people for the people.</p> 
            </p>
            <div className="row" style={{ marginBottom: "10rem" }}>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About1}
                  alt="1"
                  className="img-fluid rounded-circle"
                />
              </div>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About2}
                  alt="2"
                  className="img-fluid rounded-circle"
                />
              </div>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About3}
                  alt="3"
                  className="img-fluid rounded-circle"
                />
              </div>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About4}
                  alt="4"
                  className="img-fluid rounded-circle"
                />
              </div>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About5}
                  alt="5"
                  className="img-fluid rounded-circle"
                />
              </div>
              <div className="col-md-2 col-4 mt-5" data-aos="fade-up">
                <img
                  src={About6}
                  alt="6"
                  className="img-fluid rounded-circle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>




      <img src={nh} alt="nh" className="nh" />

      <img src={ng} alt="ng" className="ng" />
      <div className='nft' >
     <div className="content">
    <h1>Hootles in Action</h1>
    </div>

    <video src={nft} 
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop/>

    </div>


    </section>
  );
}

