
import "./Roadmap.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import Flower5 from "../assets/images/flower5.png";
import React from 'react'


export default function Roadmap() {
    return (

      <section className="ro" id="roadmap">
      <div className="container">
      <div className="row">
      <div className="col-md-9 col-xs-12 mx-auto">     
      <h1 className="road title-font">HOOTMAP</h1>
      <img src={Flower5} alt="flower" className="flower" />      
           
  <VerticalTimeline>

  <VerticalTimelineElement
   
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
         <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">Pre-launch</h4>
       <h3>Virtual Event</h3>
       <p className="bon">An exclusive virtual event for our community to learn about our vision. We will discuss our plans and members of Hootles community will have the opportunity to steer. Please follow our Discord server and Twitter for more updates. </p>
       </div>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
        <h4 className="bom">Pre-sale Mint</h4>
   <h3>Guaranteed 30% Discounted Price</h3>
   <p className="bon">Whitelisted members will be assured at least 30% discounted minting price. Please follow us on Discord or whitelist raffles and exclusive pre-sale giveaways.</p>
   </div>
  </VerticalTimelineElement>




  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
       >
         <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
         <h4 className="bom">10% Sold</h4>
       <h3>Airdrop to Early Adopters</h3> 
       <p className="bon">We value your support from day 1! We will airdrop 20 special Hootles NFTs randomly to the first 100 adopters as a gesture of gratitude.</p>
       </div>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
     >
       <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">30% Sold</h4>
       <h3>Earn as Hootles Affiliate</h3> 
       <p className="bon">For the first time, Hootles will integrate affiliate marketing in NFT community. Our community members will now have an exclusive additional opportunity to promote Hootles NFTs as an affiliate. We will offer a straight 10% commission to every mint you generate as an affiliate. Only the owners of Hootles in the community can be an affiliate. So, we offer unlimited earning potential to the owners of Hootles. 
     </p>
     </div>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
     >
       <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">50% Sold</h4>
       <h3>Launch of 'Thinkers Club' and 'Action Club'</h3> 
       <p className="bon">The members of the community will be invited to freely choose to join any of these premium clubs that will meet (virtually & in-person where possible) on a quarterly  basis.</p> 
       <p className="bon">Thinkers club members will focus to growing awareness against the historical myths about owls. Whereas, the Action club will reach out to the organizations working to preserve owl species in different regions and actively take part. 
          </p>
          </div>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
     >
       <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">75% Sold</h4>
       <h3>Exclusive Hootles Art Prints signed by Dr. Bane</h3> 
       <p className="bon">We do not want Hootles to remain just a digital asset. We want you to feel it and share the joy with your near & dear ones. So, 100 of our lucky Hootlers will receive a physical art print of their own Hootle signed by Dr. Bane, himself. Trust us, your Hootle will love to be at home. 
       </p>
       </div>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
     >
       <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">90% Sold</h4>
       <h3>Time for Noctural Hootie Parties</h3> 
       <p className="bon">It's fun time. Amidst all serious work, we want our community to have fun in a special way. Hootles are nocturnal birds, and so are the Hootlers. So, nocturnal Hootie-parties will be total fun and a great opportunity for our community to strengthen the relationship. </p>
       </div>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
     >
       <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
       <h4 className="bom">95% Sold</h4>
       <h3>Hootles Merch. Giving Away Exclusively Handcrafted Wooden Hootle Toys by Indian Tribals 
       </h3> 
       <p className="bon">Hootles come to life. We begin Hootles merchendise but uniquely. We will work towards empowering Indian tribals who worship Owls since ages. So, we plan to collaborate with the triabal groups to create exclusively hand-made wooden owl toys keeping their unique tribal art intact. We will soon launch our merch and empower the Indian tribal groups with effective collaboration.</p> 
       <p className="bon">As a gesture of gratitude, we will give the first 50 such hand-made wooden tribal toys to our lucky community members who owns minimum 5 Hootle NFTs.</p>
       </div>
  </VerticalTimelineElement>  


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
      <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
      <h4 className="bom">100% Sold</h4>
       <h3>Time For A Grand Celebration. Launching Hootles Play-2-Earn Game</h3> 
       <p className="bon">Hootles community members will have the chance to stake their NFTs against HOT tokens and earn real money from the Hootles game.</p>
       </div>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  >
    <div classname="itemRoadMap" data-aos="fade-up" data-aos-offset="300">
  <h3>It's Not an End But a New Beginning: Plans for Hootles Genesis Collection </h3> 
  <p className="bon">Coming Soon...Our Hootles community members will enjoy many special benefits in the Genesis collection. You can count on that.</p>
  </div>
  </VerticalTimelineElement>

   </VerticalTimeline>

</div>
</div>
</div>

<div className="custom-shape-divider-bottom-1649229916">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
</section>
);
}