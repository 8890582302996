import React from "react";
import "./Storyboard.css";
import {motion} from "framer-motion";
import story1 from "../assets/images/1story.png";
import story3 from "../assets/images/3story.png";
import story4 from "../assets/images/4story.png";
import Flower2 from "../assets/images/flower2.png";



export default function Storyboard() {
  return (
    <section className="storyboard" id="storyboard">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          <motion.div animate={{y:-45 , x: 8  }}
            transition={{ 
            delay: 1,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 1,
            duration: 5,
            }}
            >
            <img src={story1}  alt="" className="story1" />
            </motion.div>      
   
    
            <motion.div animate={{x:8 , y:-45}}
            transition={{ 
            delay: 1,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 1,
            duration: 5,
            }}
            >
            <img src={story3} alt="" className="story3" />  
            </motion.div>

            <div className="team-section">
              <div className="row">
              <h1 className="my-5">Premium Benefits</h1>
                <div className="col-lg-4" data-aos="fade-up">
                  <div className="box">
                    <div className="card-body text-center">
                    <h3>Assured profit by flipping: 30% discounted pre-sale minting price for our early whitelisted adopters.</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" data-aos="fade-up">
                <div className="box">
                    <div className="card-body text-center">
                    <h3>Exclusive decentralized voting rights.</h3>
                     
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Chance to win 20 special Hootles NFTs for the first 100 adopters of Hootles community.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Unlimited earning potential as Hootles affiliate. 10% straight commission on every mint that you generate.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Win an exclusive art print of your own hootle signed by Dr. Bane.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Free entry to nocturnal Hootie-parties.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Chance to win an exclusive hand-crafted wooden Hootle sculpture made by Indian tribals.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Be a part of Hootles merchendise merged with tribal welfare.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Be the reason to preserve endangered owl species and grow awareness against the myths.</h3>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4"  data-aos="fade-up">
              <div className="box">
                    <div className="card-body text-center">
                    <h3>Stake Hootle NFT in P2E game to earn.</h3>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
        </div>
        <motion.div       animate={{ y:-80, x:14  }}
        transition={{ 
        delay: 1,
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
        duration: 5,
        }}
        > 
        <img src={story4}  alt="" className="story4"  />
        </motion.div>

      </div>
      <img src={Flower2} alt="flower" className="flower" />

      
    </section>
  );
}
